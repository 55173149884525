// homepage
// .btn-secondary {
//   background: #000000;
//   border-radius: 4px;
//   color: #fff;
//   &:hover{
//     background: #313131;
//     color: #fff;
//   }
// }
// .btn-outline-secondary {
//   border: 1px solid #000000;
//   border-radius: 4px;
//   color: #000000;
// }

header {
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .logo {
    position: relative;
    width: 109px;
    height: 40px;
  }
}
.homeleft {
  .highlight {
    position: relative;
  }
  .highlight::after {
    content: '';
    position: absolute;
    background: #fcff80;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: 18px;
    z-index: -1;
  }
}
.homeright {
  .phone-mockup {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.home_page {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
  margin-bottom: 0;
}

.home_page:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../public/home/bg-home.svg') no-repeat;
  background-size: cover;
  z-index: -1;

  // -webkit-transform: translate(-10%, -60%);
  // -ms-transform: translate(-10%, -60%);
  // transform: translate(-10%, -60%);
  // content: '';
  // display: block;
  // position: absolute;
  // width: 1382px;
  // height: 780px;
  // left: 50%;
  // top: 40%;
  // background: url('../public/home/bg_gradient__main-header.svg') no-repeat bottom left/cover;
  // z-index: -1;
}

// Header
.btn-primary {
  background: linear-gradient(90.01deg, #b24592 3.46%, #f15f79 59.4%);
  border: 0;
}
.btn-light {
  background-color: #fff;
  color: #f15f79;
}

// Main home page
.homeleft {
  h1.headline {
    font-size: 4rem;
    font-weight: 800;
  }
  .subhead {
    color: rgba(0, 0, 0, 0.62);
  }
  .input-group {
    padding: 1px;
    border-radius: 4px;
    background-image: linear-gradient(90.01deg, #b24592 3.46%, #f15f79 59.4%);
    .form-control {
      border: 0;
    }
  }
}

// Launch Section
section.launch_section {
  .container {
    background-color: #fff0f3;
    border-radius: 20px;
    padding: 80px 50px;
  }
  h1 {
    font-size: 2rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .imagebox {
    width: 100%;
    height: 260px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
  }
}
section.section_bigtext {
  padding: 20px 0 40px 0;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    .text-gray {
      color: #a9a9a9;
    }
  }
}

footer {
  .footer {
    padding: 40px 0;
    border-top: solid 1px #e4e4e4;
    .footer-app-icon {
      position: relative;
      width: 150px;
      height: 42px;
      display: inline-block;
    }
    .footer-social-icon {
      display: inline-block;
      text-align: right;
    }
    .quicklinks {
      ul {
        list-style-type: none;
        padding: 0;
        li {
          display: inline-block;
          margin-right: 20px;
          a {
            text-decoration: none;
            color: var(--bs-body-color);
          }
        }
      }
    }
    .madein {
      text-align: center;
    }
  }
}

// inner page ===> start
.inner_page {
  .header__wrapper {
    background-image: url('/home/bg-subpage.svg');
    background-size: cover;
  }
}
// inner page ===> end
@media (max-width: 575.98px) {
  .beamerButton {
    display: none;
  }
  .mob-w-100 {
    width: 100% !important;
  }
  .homeleft {
    .headline {
      font-size: 2.5rem !important;
    }
    button {
      width: 100%;
    }
    .highlight::after {
      height: 12px;
      bottom: 8px;
    }
  }
  section.launch_section {
    .container {
      border-radius: 0;
    }
    .imagebox {
      height: 200px;
    }
  }
  section.section_bigtext {
    h1 {
      font-size: 2.2rem;
    }
  }
  .auth-right-sidebar {
    display: none !important;
  }
  .auth-left-box {
    padding: 40px 15px;
  }
  footer {
    .footer-app-icons {
      text-align: center;
    }
    .quicklinks {
      text-align: center;
    }
    .footer-social-icons {
      text-align: center !important;
      margin: 20px 0;
    }
  }
}

.btn-explore {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
  background: rgba(0, 0, 0, 0.04);
}
.br-8 {
  border-radius: 8px;
}
.input-group-text {
  border: none;
  font-weight: 700;
  color: rgb(0 0 0 / 50%);
}
.dashboard_wrapper {
  nav {
    background-color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
    box-shadow: 0 0px 8px rgb(0 0 0 / 20%);
    padding: 10px 0 !important;
    backdrop-filter: blur(4px);
    .dropdown-menu {
      .dropdown-item {
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        padding: 0.45rem 1rem;
      }
    }
  }
  .subheader {
    a {
      color: #313132;
      font-weight: 700;
      text-decoration: none;
      display: inline-block;
    }
    a.active {
      background: rgb(255 255 255);
      border-radius: 4px;
      padding: 4px;
      border-bottom: 3px solid #313132;
      // border-bottom: 3px solid rgb(0 0 0 / 12%);
      // .subheader_svg {
      //   fill: #313132;
      // }
    }
    .icon {
      position: relative;
      width: 18px;
      height: 18px;
      display: inline-block;
      margin-right: 8px;
    }
  }

  .theme__page {
    .imagebox {
      position: relative;
      min-height: 200px;
      border-radius: 8px;
      transition: all 0.5s ease;
      img {
        border-radius: 8px;
        border: 1px solid rgb(0 0 0 / 7%) !important;
      }
      &:hover {
        transform: scale(1.03);
      }
    }

    .themebox.selected {
      border: 2px solid #29d;
    }
  }

  .stats__page {
    .stats_link {
      display: flex;
      border-bottom: 1px solid #f2f2f2;
      .stats_link__title,
      .stats_link_counter {
        font-weight: 700;
      }
      .stats_link__url,
      .stats_link_counter_text {
        color: rgb(52 52 52 / 65%);
        font-size: 0.85rem;
      }
    }
  }

  .card.feeditem .card-body {
    padding: 16px;
  }
  .card {
    .card-body {
      padding: 32px;
      h4 {
        font-size: 1.2rem;
        font-weight: 700;
        color: rgb(0 0 0 / 80%);
      }
    }
  }
}
@media (max-width: 575.98px) {
  .mobile-nav-link {
    margin-top: 30px;
    a {
      display: block;
      padding: 8px 0px;
      font-size: 1.2rem;
      text-decoration: none;
      color: #313132;
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
  }
  .dashboard_wrapper {
    .link-buttons,
    .subheader {
      white-space: nowrap;
      overflow: scroll;
    }
    .subheader {
      padding-top: 10px;

      position: fixed;
      bottom: 0;
      z-index: 9;
      background-color: #fdfefe;
      left: 0;
      right: 0;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-between !important;
      // flex-wrap: nowrap;
      // overflow-x: auto;

      // -webkit-overflow-scrolling: touch;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      a {
        flex: 0 0 auto;
        margin-right: 3px;
        font-size: 0.75rem;
        font-weight: 500;
        .subheader__menu {
          flex-direction: column !important;
          justify-content: center !important;
          .icon {
            display: block;
            margin-right: 0;
            margin-bottom: 4px;
            // width: 100%;
            // height: 100%;
          }
        }
      }
      a.active {
        border-bottom: 2px solid #313132;
        border-radius: 0;
      }
    }
  }

  #crisp-chatbox {
    a {
      bottom: 74px !important;
    }
  }
}
/* Nested Spinner Start */
.nested-spinner {
  position: relative;
  ._spinner {
    position: absolute;
    top: 50%;
    z-index: 4;
    display: inline-block;
    inset-inline-start: 50%;
    margin: -10px;
  }
  .spin-container {
    transition: opacity 0.3s;
    &.spin-blur {
      opacity: 0.3;
      user-select: none;
      pointer-events: none;
    }
  }
  // ::after{
  //   content: "";
  //   background-color: red;
  // }
}
/* Nested Spinner End*/

// explore modal css
.fs-14 {
  font-size: 0.875rem;
}
.fs-20 {
  font-size: 1.25rem;
}
.explore__modal {
  .modal-body {
    padding: 40px 60px;
    .explore_title {
      margin-bottom: 30px;
    }
    .explore_app_thumbnail {
      position: relative;
      width: 42px;
      height: 42px;
      margin-right: 15px;
      margin-top: 4px;
      border-radius: 4px;
      overflow: hidden;
    }
    .explore_app_title {
      font-weight: 600;
    }
    .explore_app_desc {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.47);
    }
  }
}

// 1LinksApp Page
// =================================================================
// Social Media Icons
main.public-profile-page {
  padding: 32px 16px 32px;
  width: 100%;
  min-height: calc(100vh - 150px);
  min-height: -moz-calc(100vh - 150px);
  min-height: -webkit-calc(100vh - 150px);

  .share-btn {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
  }
}
.page-socialmedia {
  .sm-icon {
    position: relative;
    display: inline-block;

    opacity: 0.65;
    svg {
      fill: #000;
    }
  }
}
.page-item-wrapper {
  a.page-item-a {
    position: relative;
    padding: 0 60px;
    text-align: center;
    .item-image-box {
      position: absolute;
      height: 44px;
      top: 8px;
      left: 8px;
      img {
        border-radius: 10px;
      }
    }
  }

  .fb_iframe_widget {
    display: block;
    border-radius: 10px;
    overflow: hidden;
    min-height: 180px;
    background-color: #000;
  }
  .facebook-embed_wrapper {
    padding: 8px;
  }
}
.modal.public_profile__popup {
  iframe.instagram-media {
    margin-bottom: 0 !important;
  }
  .twitter-tweet {
    margin: 0 !important;
  }
}
.share_list__title {
  font-weight: 600;
  font-size: 1rem;
}
ul.share_list {
  list-style-type: none;
  padding: 16px;
  margin: 0;
  li {
    a {
      display: flex;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      text-decoration: none;
      color: #000;
      border-radius: 8px;
      transition: background-color 0.1s ease-in 0s;

      &:hover {
        background: rgb(243, 243, 241);
      }
      .share_list__text {
        font-weight: 600;
        width: 100%;
      }
    }

    .copyclipboard_container {
      padding: 16px;
      .copyclipboard {
        border: 1px solid rgb(224, 226, 217);
        height: 60px;
        background: none;
        border-radius: 8px;
        padding: 0px 0px 0px 12px;
        cursor: pointer;
        &:hover{
          background-color: rgb(243, 243, 241);
        }
        .copyclipboard__wrapper {
          display: flex;
          height: 100%;
        }

        .link_box {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        .link_box_copy_txt {
          display: flex;
          height: 100%;
          width: 100px;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
        }
      }
    }
  }
}
// ==========================END===========================================

// Custom Modal Animation === START
.link_modal {
  ._subscription_items {
    background-color: #f5f8fa;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 8px;
    font-size: 0.85rem;
  }
  .card {
    // border: 1px solid rgba(0, 0, 0, 0.125);
    border: 2px dashed rgba(0, 0, 0, 0.125);
  }
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: scale(0.8);
}
.modal.show .modal-dialog {
  transform: none;
}
.modal-backdrop.show {
  opacity: 0.7;
}
.modal-content {
  border-radius: 12px;
}
// .modal.fade .modal-dialog{transition:transform 0.3s ease-out;transform:translate(0, -25%)}

// .modal.scale .modal-dialog{transition:transform 0.5s cubic-bezier(0.36, 1, 0.2, 1);
// transform:scale(0.5);will-change:transform}

// .modal.show .modal-dialog{transform:translate(0, 0) scale(1)}

// Custom Modal Animation ==== END

.__app_telegram_public {
  h4 {
    color: #8d8d8d;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .subscription_plan {
    display: block;
    padding: 20px;
    background-color: #fffcf3;
    border: 1px solid #d7d7d7;
    border-radius: 8px;
    max-width: 600px;
    margin-bottom: 20px;
    .duration {
      padding-left: 10px;
    }
    .price {
      font-weight: 700;
    }
  }
}
