// Required



// Default variable overrides
// $body-bg: #000;
// $body-color: #111;
// $primary: #FFF222;
$primary: #007c89;
$danger: #ff4136;
$dark: #313132;

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "dark": $dark
);
@import "../node_modules/bootstrap/scss/bootstrap";

// // Required
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";
// @import "../node_modules/bootstrap/scss/root";

// // Optional Bootstrap components here
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// // etc