html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Nunito', sans-serif;
}
/* Form Control */
.form-label {
  color: #3f4254;
  font-weight: 500;
}

.form-control.form-control-solid {
  padding: 0.5rem 0.75rem;
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #5e6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.form-control.form-control-solid::placeholder {
  color: #cfcfcf;
}
.form-control.form-control-solid.active,
.form-control.form-control-solid.focus,
.form-control.form-control-solid:active,
.form-control.form-control-solid:focus {
  background-color: #eef3f7;
  border-color: #eef3f7;
  color: #5e6278;
  box-shadow: none;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.form-select.form-control-solid {
  padding: 0.5rem 0.75rem;
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
}
/* Form Control End */

/* Create Link Modal */
.input_file_upload__wrapper {
  position: relative;
  border: 1px dotted #adadad;
  border-radius: 8px;

  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input_file_upload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
}
/* END Create Link Modal */
.btn {
  position: relative;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}
.button--loading::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.button--loading.loader_gray::after {
  border-top-color: #b6b6bc !important;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.auth-right-sidebar {
  background: rgb(131, 58, 180);
  /* background: linear-gradient(
    126deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  ); */
  background: linear-gradient(126deg, #b24592, #c32d48);
  height: 100vh;
}
.w-500 {
  width: 500px;
}

/* dropdown animation start */
@media (min-width: 768px) {
  .dropdown-menu {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.dropdown-menu {
  border-radius: 0.475rem;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  border: 0;
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
/* dropdown animation end */

/* card */
.card {
  border: none;
}
.card .card-body {
  padding: 1.5rem 1rem;
}
.card .feeditem__title,
.card .feeditem__url {
  color: rgb(0 0 0 / 76%);
  word-break: break-all;
}
.card .draghandler {
  cursor: -webkit-grab;
  cursor: grab;
}

/* Phone view */
.bl-phone-view-main {
  /* position: relative; */
  position: sticky;
  width: 350px;
  max-width: 350px;
  border: 15px solid #222;
  border-radius: 40px;
  height: 723px;
  max-height: 723px;
  transform-origin: right top;
  transform-origin: 0 0;
  /* background-color: #222; */
  overflow: hidden;
  top: 100px;
}

/* card end */

